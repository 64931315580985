.ltr .search-on-doc--bar-position.pinned {
  left: auto;
  right: -50px;
}


.ltr .unit-materials .search-on-doc--bar-position.pinned {
  right: -25px;
  left: auto;
}

.unit-materials .search-on-doc--bar-position.pinned {

  right: auto;
  left: -25px;
}

.ltr .likutim .search-on-doc--bar-position.pinned {
  right: -50px;
  left: auto;
}

.likutim .search-on-doc--bar-position.pinned {
  right: auto;
  left: -50px;
}

.search-on-doc- {
  &-container {
    position: relative;

    .social-buttons {
      align-items: center;
      background: rgba($black, .8);
      border-radius: calc(36px / 2 + .5rem);
      display: flex;
      margin: auto 0 1rem;
      padding: .5rem .25rem;

      .embed-share-button {
        vertical-align: text-bottom;
      }
    }
  }

  &-share {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &-bar-position {
    position: absolute;
    z-index: 999;
    width: 100%;

    &.pinned {
      width: max-content;
      left: -50px;
      right: auto;
      padding: 2px;
      border-radius: 8px;
      border: 1px solid $blue;

    }
  }

  &-toolbar {
    position: relative;
    border: none !important;
    box-shadow: none !important;
    transform: none !important;
    width: min-content;
    background: $black;
    margin: 0 auto;
    border-radius: 8px;
    text-align: center;
    opacity: 0.95;

    .pinned & {
      background: $white;

      & > .button {
        margin: 0 2px 4px !important;
        float: none;
      }
    }

    & > .header {
      color: $white;
      margin-top: 4px;
      padding: 1rem 1em 0;
    }

    & > .button {
      margin: 4px !important;

      &:first-child {
        margin-bottom: 1em !important;
      }

      &.pin {
        box-shadow: none !important;
        font-size: 12px;
        line-height: 20px;
        height: 20px;
        width: 20px;
        padding: 0;
      }

      &.circular {
        width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 !important;
        font-size: 10px;

        .icon {
          font-size: 1em;
        }

        .attached.label {
          padding: 4px 8px;
          width: auto;
        }
      }
    }

    .ui.menu {
      background: transparent !important;
      width: max-content;
      padding: 1em;

      .item {
        flex-direction: column;
        color: $white;
        text-align: center;
        font-size: 0.8em;
        padding: 1.4em 2em;

        & > .button {
          margin-bottom: 8px;
          background-color: $white;
          height: 40px;
          width: 40px;

          svg {
            height: 2.2em;
            width: 2.2em;
            color: $grey;
          }

          .icon {
            font-size: 1.7em;
            line-height: 1em;

          }
        }
      }
    }
  }
}
