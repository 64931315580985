$border-color: rgba(34, 36, 38, .15);
$red: #db2828;
$orange: #f2711c;
$yellow: #fbbd08;
$olive: #b5cc18;
$green: #21ba45;
$teal: #00b5ad;
$blue: #3376D6;
$blue-semantic: #2185d0;
// $blue: #00aeef; //bb style guide blue
$violet: #6435c9;
$purple: #a333c8;
$pink: #e03997;
$brown: #a5673f;
$midGrey: #828282;
$grey: #767676;
$black: #1b1c1d;
$stroke-gray: #E8E8E8;

$active: #c2c1c1;

$light-text: #757575;
$light-stroke: #EFECEB;
$light-active-text: #1A4378;
$light-hover-btn: #1C5DBB; 

$sepia-bg: #f4ecd8;
$sepia-text: #5B4636;
$sepia-stroke: #c0a596;
$sepia-active-text: #1A4378;

$dark-bg: #010101;
$dark-text: #D4D4D4;
$dark-hover: #767676;
$dark-btn-hover: #A0D6FF;
$dark-stroke: rgba(242, 242, 242, .3);
$dark-active-text: #1C5DBB;

$text-color: #212121;

// Neutrals
$fullBlack: #000;
$offWhite: #f9fafb;
$darkWhite: #f3f4f5;
$midWhite: #dcddde;
$white: #fff;
$text-colors: (
        red: $red,
        orange: $orange,
        yellow: #b58105, // Yellow text is difficult to read
        olive: #8abc1e, // Olive is difficult to read
        green: #1ebc30, // Green is difficult to read
        teal: #10a3a3, // Teal text is difficult to read
        blue: $blue,
        violet: $violet,
        purple: $purple,
        pink: $pink,
        brown: $brown,
        grey: $grey,
        black: $black,
        white: $white
);

@each $colorname, $colorvalue in $text-colors {
  .text.#{"" + $colorname} {
    color: #{$colorvalue};
  }
}
