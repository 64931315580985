$sidebar-width: 300px;
$main-menu-height: 60px;
$secondary-header-height: 10px;
$max-readble-width: 700px;
$breadcrumb-height: 56px;
$text-bar-height: 70px;

@font-face {
  font-family: 'AmiriArabic';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/amiri/v27/J7aRnpd8CGxBHpUrtLMA7w.woff2) format('woff2');
  unicode-range: U+0600-06FF, U+0750-077F, U+0870-088E, U+0890-0891, U+0898-08E1, U+08E3-08FF, U+200C-200E, U+2010-2011, U+204F, U+2E41, U+FB50-FDFF, U+FE70-FE74, U+FE76-FEFC;
}


:root {
  scrollbar-color: #646464 #FFFFFF;
}

h1, h2, h3, h4, h5, .ui.accordion .title:not(.ui), .ui.button, .ui.input > input {
  font-family: AmiriArabic, "Assistant", Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

body {
  overflow-y: scroll;
  font-family: AmiriArabic, "Assistant", Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;

  &.noscroll {
    overflow: hidden;

    &--smallmobile {
      @include small-mobile {
        overflow: hidden;
      }
    }
  }

}

@import 'rtl/layout';

.layout {
  height: 100vh;

  &__header,
  &__sidebar {
    .menu,
    .item {
      border-radius: 0 !important;
    }

    > .menu {
      height: $main-menu-height;

      small {
        line-height: 1;
      }
    }
  }

  &__header {
    left: 0;
    right: 0;
    top: 0;
    z-index: 2000;
  }

  &__search {
    flex: 1 0 auto !important;
    font-size: 1rem;
    line-height: 1.4285em !important;
  }

  &__search_max_width {
    @include small-mobile {
      max-width: 150px;
    }
  }

  &__sidebar {
    @include small-mobile {
      width: 100%;
    }
    background: $white;
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    transform: translate(-100%, 0);
    transition: transform .3s;
    width: $sidebar-width;
    z-index: 900;

    &.is-active {
      transform: none;
    }

    > .menu {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
    }

    &-menu {
      border-right: 1px solid $border-color;
      bottom: 0;
      left: 0;
      overflow: auto;
      position: absolute;
      right: 0;
      top: $main-menu-height;

      .menu {
        border: 0;
        box-shadow: none !important;
      }
    }

    &-search {
      flex: 1 0 auto !important;
    }
  }

  &__main {
    min-height: 100%;
  }

  &__footer {
    background: $black;
  }

  &__secondary-header {
    background: $white;
    border-bottom: 1px solid $border-color;
    left: 0;
    min-height: $secondary-header-height;
    right: 0;

    .row {
      padding-bottom: .5rem !important;
      padding-top: .5rem !important;
    }

    + .ui.container {
      background-clip: content-box;
      padding-top: $secondary-header-height;
    }
  }
}

.logo {
  flex: 0 1 auto !important;
  padding: 0 !important;

  img {
    display: inline-block !important;
  }

  h1.header {
    @include mobile {
      font-size: 1.28571429rem !important;
    }
    font-size: 1.71428571rem;
    margin-left: 16px;
    margin-top: 0;
  }
}

.content {
  &__aside {
    &-unit {
      padding: 1em;
      margin-bottom: 2em;
      width: 100%
    }
  }
}

@media only screen and (min-width: $largeMonitorBreakpoint) {
  .layout:not(.is_fullscreen) {
    .layout__sidebar-toggle {
      display: none !important;
    }

    .layout__sidebar {
      top: $main-menu-height;
      transform: none;

      > .menu {
        display: none;
      }

      &-menu {
        top: 0;
      }
    }

    .layout__main {
      margin-left: $sidebar-width;
    }

    .layout__secondary-header {
      left: $sidebar-width;
    }
  }

  .logo {
    min-width: $sidebar-width;
  }
}

.ui.container.padded {
  padding: 1em;

  &.vertically {
    padding: 1em 0;
  }

  &.horizontally {
    padding: 0 1em;

    &.vertically {
      padding: 1em;
    }
  }
}

.ui.grid {
  .is-fitted {
    padding: 0 !important;
  }
}

.ui.menu {
  .donate-button {
    &.ui.button,
    &.ui.button:hover,
    &.ui.button:focus {
      background: #fc6719 !important;
      color: white !important;
      font-size: .92857143rem !important;
    }
  }

  .auth-button {
    &.ui.button,
    &.ui.button:hover,
    &.ui.button:focus {

      background: $white !important;
      font-size: 1.8em !important;
      width: 2.9rem;
      text-align: center;
      padding: 0;
      line-height: 1.3em;

      @include mobile {
        font-size: 1rem !important;
        width: 2.3rem;
        line-height: 2.3rem;
      }
    }
  }

  .vh-button {
    font-size: .92857143rem !important;
    margin: 0 0.5em !important;

    &.ui.button,
    &.ui.button:hover,
    &.ui.button:focus {
      background: $white !important;
    }

  }
}

.auth-popup {
  text-align: center;
  padding: 0 2em;

  .ui.list > a {
    &.item, &.item:hover, &.item:focus {
      color: $black;
      padding: 0.5em 0;
    }
  }
}

.splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.layout__header-buttons > .item {
  padding-right: 0.7em !important;
  padding-left: 0.7em !important;
}

.tooltip_if_need {
  max-width: 50% !important;
  width: fit-content !important;
  transform: translateX(50%) !important;
}

.donationPopup {
  .donate-button {
    &.ui.button,
    &.ui.button:hover,
    &.ui.button:focus {
      background: #fc6719 !important;
      color: white !important;
      font-size: .92857143rem !important;

      i {
        margin-left: 6px !important;
        margin-right: 0px !important;
        float: right;
      }
    }
  }

  i.close.icon {
    color: #000;
  }

  .popupHeader {
    height: 2em;
  }
}

.donationPopup.rtl {
  .donate-button {
    &.ui.button,
    &.ui.button:hover,
    &.ui.button:focus {
      i {
        margin-left: 0px !important;
        margin-right: 6px !important;
        float: left !important;
      }
    }
  }
}

.headroom-z-index-801 .headroom {
  z-index: 801 !important;
}

.headroom-z-index-802 .headroom {
  z-index: 802 !important;
}

.layout__content {
  .headroom--pinned {
    transform: translateY($main-menu-height) !important;
  }
}

.ltr {
  .layout__content {
    .headroom--pinned {
      @include tablet-mobile {
        margin-left: 0;
        width: 100%;
      }

      margin-left: $sidebar-width;
    }
  }
}

.rtl {
  .layout__content {
    .headroom--pinned {
      @include tablet-mobile {
        margin-right: 0;
        width: 100%;
      }

      margin-right: $sidebar-width;
      margin-left: 0;
    }
  }
}