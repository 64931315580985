.unit-info {
  @include mobile {
    margin: 0 1em 1em 1em;
  }
  clear: both;
  margin: 0 1em 2em 0;

  &__name {
    font-weight: normal;
  }

  &__film-date {
    font-size: 1rem;

    .separator {
      margin: 0 0.5em;
    }
  }

  &__collections a {
    font-size: 1.46rem;
  }

  &__header {
    margin-top: 0.5rem !important;
  }

  .link_to_cu {
    padding: 4px 8px;
    margin-top: 8px !important;
    margin-right: 4px !important;
    margin-left: 0 !important;
  }

  .unit-info__title {
    line-height: 2.5em;
    color: red !important;

    img {
      height: 2.5em;
      width: 2.5em;
      float: left;
      margin-right: .5em;
    }
  }


}

.rtl {
  .unit-info {
    .link_to_cu {
      margin-right: 0 !important;
      margin-left: 4px !important;
    }

    .unit-info__title {
      img {
        float: right;
        margin-left: .5em;
      }
    }
  }
}

.unit-materials {
  @include mobile {
    padding: 0;
  }

  background: $darkWhite;

  & > .menu {
    @include mobile {
      overflow-x: auto;
      overflow-y: hidden;
      border-bottom: 0 !important;
    }

    padding: 0 0 0.5em 0;
    margin: 0;

    .item {
      @include mobile {
        flex: 2 0 auto !important;
        padding: 1em 0.5em !important;
      }

      padding: 1em !important;
    }
  }

}

.unit-tags-bar {
  display: flex;
  overflow: hidden;

  .unit-tags-bar_tags {
    scrollbar-gutter: stable;
    overflow-y: hidden;
    overflow-x: scroll;
    white-space: nowrap;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }


    .unit-tag-item {
      scroll-snap-align: start;
      padding: 8px 14px;
      margin: 0 4px;
      background: rgba(51, 118, 214, 0.08);
      box-shadow: none;
      font-weight: normal;

      a {
        color: $blue;
      }

      &:hover {
        background: rgba(28, 93, 187, 0.08);

        a {
          color: #1C5DBB;
        }
      }

      &:active {
        background: rgba(26, 67, 120, 0.08);

        a {
          color: #1A4378;
        }
      }

      .is-dark & {
        background: rgba(51, 118, 214, 0.28);

        a {
          color: $blue;
        }

        &:hover {
          background: rgba(160, 214, 255, 0.28);

          a {
            color: #A0D6FF;
          }
        }

        &:active {
          background: rgba(28, 93, 187, 0.28);

          a {
            color: #1C5DBB;
          }
        }
      }
    }
  }

  .unit-tags-title {
    white-space: nowrap;
    line-height: 28px;
  }
}