@use "sass:math";

@import 'rtl/homepage';

.homepage {
  flex: 1 1 auto;

  &__featured {
    padding-top: 4em !important;
  }

  &__section {
    @include mobile {
      margin-bottom: 1rem;
      margin-top: 1rem;
    }
    margin-bottom: 0;
    margin-top: 0;

    .ui.horizontal.divider {
      &::before,
      &::after {
        background-image: linear-gradient(to right, lighten($blue, 40%), lighten($blue, 40%));
        background-size: 100% 1px;
      }
    }

    &__iconsrow {
      .iconsTitleRow {

        .iconsTitle {
          position: relative;
          top: 40px;
          background: #FFF;
          font-size: 2.25em;
          font-weight: bold;
          padding: 12px 30px 17px 30px;
          border: 2px solid #DBECF9;
          border-radius: 9px;
          z-index: 1;
          @include mobile {
            font-size: 1.5em;
          }
        }
      }
    }


    &__latestUpdates {
      background: rgba(44, 144, 220, 0.05);
      border-top: 2px solid #DBECF9;
      padding: 0 55px;

      @include mobile {
        padding: 1em;
      }

      .cardsTitle {
        width: 100%;
        padding: 2em 0 0 0;
        font-size: 1.5em;
        font-weight: 700;
        color: #134D78;
        margin-bottom: 0.5em;
        @include mobile {
          font-size: 1.5em;
          padding: 2em 0 0 0em;
          margin-bottom: 0;
        }
      }
    }
  }

  .latestUpdateSection {
    padding-top: 1em;

    .row {
      padding-top: 0 !important;
    }
  }

  .home-social-section {
    @include mobile {
      margin-bottom: 1rem;
    }
    margin-bottom: 4em;
  }

  &__header {
    @include mobile {
      padding-bottom: 2rem;
      padding-top: 1rem;
    }
    background-attachment: fixed, scroll, scroll;
    background-image: linear-gradient(to bottom, $blue-semantic, transparent),
    radial-gradient(ellipse at bottom center, lighten($blue-semantic, 15%) 0, transparent 50%),
    linear-gradient(to bottom, $blue-semantic, lighten($blue-semantic, 5%));
    background-position: center $main-menu-height, center center, center center;
    background-repeat: no-repeat;
    background-size: 100% 5rem, cover, cover;
    margin-top: 0;
    padding-bottom: 4rem;
    padding-top: 2rem;
  }

  &__title {
    @include mobile {
      display: none;
    }
    margin-bottom: .4em !important;
  }

  &__website-sections {
    .header {
      color: darken($blue, 20%);
      transition: color .2s ease-out;

      &:hover {
        color: $blue;
      }
    }
  }

  &__iconsrow {
    background: #F3F4F5;
    border-top: 2px solid #DBECF9;
    padding-top: 1.5em !important;

    .iconsTitleRow {
      background: #FFF;

      .iconsTitle {
        position: relative;
        top: 40px;
        background: #FFF;
        font-size: 2.25em;
        font-weight: bold;
        padding: 12px 30px 17px 30px;
        border: 2px solid #DBECF9;
        border-radius: 9px;
        z-index: 1;
        @include mobile {
          font-size: 1.5em;
        }
      }
    }

    .activeSectionsIcons {
      padding-top: 1.5em !important;
    }

    img {
      margin-bottom: .2em;
      transition: transform .2s ease-out;
      width: 50px !important;
    }

    .row .center.aligned.column {
      align-items: stretch;
      display: flex !important;
      justify-content: center;

      &.topIcon {

        padding-bottom: 3em;
      }
    }

    a {
      border-radius: 4px;
      flex: 1 1 auto;
      padding: 4px 4px 8px !important;

      &:hover {
        img {
          transform: translateY(-.3rem);
        }
      }
    }

  }

  &__iconsrow.socialBackground {
    background: #F4F9FB;
    margin-left: 0;
    margin-right: 0;
  }

  &__search {
    input {
      border-radius: 4px !important;
      font-size: 16px;
    }

    .search-omnibox {
      max-width: none;
    }
  }

  &-posters {
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    .column {
      padding: 0 !important;
    }
  }
}

.thumbnail {
  @include mobile {
    margin-bottom: 1rem;
    padding-top: percentage(math.div(1, 2.5));
  }
  height: 0;
  overflow: hidden;
  padding-top: percentage(math.div(9, 16));
  position: relative;

  .label {
    position: absolute;
    right: 1em;
    text-transform: uppercase;
    top: 1em;
  }

  a {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &::before {
      background-image: linear-gradient(to top, rgba($black, .9), transparent);
      bottom: 0;
      content: '';
      height: 50%;
      opacity: .8;
      position: absolute;
      transition: opacity .3s;
      width: 100%;
      z-index: 1;
    }
  }

  &:hover {
    .thumbnail__header {
      transform: translate(0, -.5em);
    }

    a {
      &::before {
        opacity: 1;
      }
    }
  }

  &__header {
    bottom: .5em;
    left: .5em;
    margin: 0 !important;
    position: absolute;
    right: .5em;
    text-shadow: 0 1px $black, 0 2px $black;
    transition: transform .5s;
    z-index: 2;

    .content,
    .sub {
      color: $white !important;
      font-weight: 400;
    }

    .sub {
      text-transform: uppercase;
    }

    .homepage-posters & {
      bottom: 1em;
      left: 1em;
      right: 1em;
    }
  }

  &__image {
    position: absolute !important;
    top: 0;
    z-index: 0;
  }

  .homepage-posters & {
    padding-top: percentage(math.div(1.6, 1));

    &__image {
      background-position: center center;
      background-size: cover;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }
  }
}

