@use "sass:math";

.avbox {
  @include mobile {
    margin: 0 !important;
    padding: 0 !important;
  }

  background-color: $darkWhite;
  width: 100% !important;

  .unit_container {
    @include mobile {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }

    background-color: $darkWhite;
    padding: 1em;
    width: 100%
  }

  /* player on mobile devices */
  &__player.mobile-device {
    height: auto;
    padding: 0;

    .avbox__media-wrapper {
      overflow: visible;
      padding-top: 0;
      position: relative;
    }

    .mediaplayer__wrapper {
      position: relative;
    }

    audio {
      margin: 0;
    }

    select {
      background: transparent;
      border-width: 0;
      padding: 0.8em 0;
      max-width: 8rem;

      &:focus {
        outline: none;
      }
    }
  }

  &__player {
    background: $black;
    height: 0;
    padding-top: percentage(math.div(9, 16));
    position: relative;

    &--is-4x3 {
      padding-top: percentage(3 * 0.25);
    }

    &--is-audio {
      padding-top: 100px;

      &--edit-mode {
        height: 400px;
        -webkit-transition: height 1s;
        transition: height 1s;
      }

      @media screen and (min-width: $tabletBreakpoint) {
        &--edit-mode {
          height: 310px;
        }
      }

      &--normal-mode {
        height: 0;
        -webkit-transition: height 1s;
        transition: height 1s;
      }

      &--dropdown-opened {
        height: 280px;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
      }

      &--dropdown-closed {
        height: 0;
        -webkit-transition: height 0.5s;
        transition: height 0.5s;
      }
    }
  }

  &__media-wrapper {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
  }

  #avbox_playlist {
    max-height: 40rem;

    > .header, .sub {
      color: $darkWhite;
      align-items: flex-start;
    }

    > .header {
      background-image: linear-gradient(0deg, #1b1c1d, rgba(27, 28, 29, .85));
      @include mobile {
        padding: 1em 0.5em;
        font-size: 18px;

        & small {
          font-size: 14px !important;

        }
      }
      font-size: 2.0rem;
      padding: 0.5em;
      margin-top: 0;
      margin-bottom: 0;

      .content {
        padding: 0 1em;
        vertical-align: middle;
      }

      .playlist_icon {
        vertical-align: bottom;
      }

      & small {
        font-size: 70%;
        padding: 5px;
      }

      &.h3 {
        font-size: 1.5rem;
      }
    }
  }

  &__playlist-wrapper {
    @include mobile {
      display: block;
      padding-bottom: 1em;
      padding-top: -1em;
      left: 0;
      right: 0;
      position: relative;
      overflow-y: auto;

      &::before {
        content: none;
      }

      > .header {
        &::before {
          content: none;
        }
      }
    }

    background: $darkWhite;
    display: flex;
    flex-direction: column;

    > .header {
      flex: 0 0 auto;
      position: relative;
      padding: 0 0.5em;
    }
  }

  &__playlist-view {
    border: 1px solid $midWhite;
    clear: both;
    overflow-y: auto;
    position: relative;
    margin: 0;

    &.container {
      border: 1px solid $midWhite;
    }

    .header {
      color: $blue;
    }

    .list {
      width: 100%;

      .item {
        padding: 0 !important;

        .active {
          background-color: rgba($blue, .2) !important;
        }

        table {
          width: 100%;
          background-color: inherit;
          color: inherit;
        }
      }
    }


    .menu > .item {
      &.active {
        background-color: rgba($blue, .2) !important;
      }
    }
  }
}


.avbox__playlist-prev-button, .avbox__playlist-next-button {
  @include mobile {
    padding: 0 4px;
  }

  i.icon {
    margin: 0 .25rem !important;
    color: white;
    width: 15px;
    display: inline-block;

    &:hover {
      opacity: 0.8;
    }
  }
}


.cut_and_download_btn, .embed-share-button {
  padding: 0 !important;
  margin: 0 1px !important;
  position: relative;
  font-size: 1.7em !important;
  align-self: flex-start;

  & > .icon {
    margin: 0 !important;
    width: auto !important;
  }

  & > .ui.floating.label {
    right: 100%;
    left: auto;
    margin: 0 -1.5em 0 !important;
  }
}


.rtl .cut_and_download_btn, .embed-share-button {

  & > .ui.floating.label {
    left: 100%;
    right: auto;
  }
}


.ui.modal > .content.cut_and_download_modal {
  text-align: center;
  padding: 3em 1em !important;

  & > .header {
    padding-bottom: 1em;
  }

  & > .container {
    padding-top: 2em;
  }

  .button {
    margin: 0 1em;
  }
}
