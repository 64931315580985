
.cu_items.ui.four.cards > .card {
  width: calc(25% - 74px);
  margin: 37px;
  overflow: hidden;
  @include tablet {
    width: calc(50% - 40px);
    margin: 20px;
  }
  @include mobile {
    width: calc(100% - 5em) !important;
    margin: 2.5em;
    &:first-child {
      margin-top: 2.5em !important;
    }
  }
}

.cu_item {
  -webkit-tap-highlight-color: transparent;

  @include mobile {
    .content {
      font-size: 1.3em !important;
    }
  }

  .cu_item_img {
    position: relative;
    width: 100%;
    height: auto;

    .ui.image {
      display: block;
    }


    .cu_item_img_info {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;

      .cu_item_info_co {
        position: absolute;
        bottom: 0.5em;
        width: 100%;
        display: flex;
        flex: 0 0 50px;
        flex-flow: nowrap;

        .ui.image, svg {
          height: 3.5em !important;
          flex: 0 0 auto;
          width: 50px;
        }

        svg {
          width: 70px;
        }

        .header {
          width: 100%;
          color: white;
          padding: 4px 8px;
          align-self: center;
          margin: 0;
        }
      }
    }

    .ui.card > .content, .ui.cards > .card > .content {
      border-top: 0;
    }
  }

}

.card.cu_item {
  .cu_item_info_co {
    background-image: linear-gradient(0deg, #1b1c1d, rgba(27, 28, 29, .85));
  }
}


.cu_item_duration {
  font-size: 0.8em;
  border-radius: 0;
  background-color: $black;
  color: white;
  margin: 1em;
  padding: 3px 4px;
  display: inline-block;

  @include mobile {
    margin: 4px;
  }
}

.cu_info_description {
  margin: 0 1em 1em 0.5em;

  & > span {
    display: inline-block;
    padding: 0 0.5em;
    font-size: .9em;
    line-height: 1em;
    border-left: 1px $midGrey solid;
    border-right: 1px $midGrey solid;
  }
}


.ltr > .cu_info_description > span {
  border-right-width: 0;

  &:first-child {
    border-left: 0;
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.rtl > .cu_info_description > span {
  border-left-width: 0;

  &:first-child {
    padding-right: 0;
    border-right: 0;
  }

  &:last-child {
    padding-left: 0;
  }
}

.cu_item_info_co {
  .ui.image, svg {
    height: 2.5em !important;
    margin: 0;
  }
}

.cu_item_progress.ui.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0;
  background: $grey;
  margin: 0;
  border-radius: 0;
  z-index: 2;
  direction: ltr;

  .bar {
    background: #04eaf6;
    border-radius: 0;
    min-width: auto;
    @include mobile {
      height: 3px;
    }
  }
}

.ui.container.cu_item_list {
  border-bottom: 1px solid $midWhite;
  display: flex;
  justify-content: start;
  margin: 0 0 1px;
  position: relative;

  &:last-child {
    border: 0;
  }

  & > div {
    position: relative;
  }

  @include mobile {
    padding-top: 17px;
    padding-bottom: 17px;
    min-height: 120px;
  }

  &.selected {
    background-color: $midWhite;
  }

  .column {
    position: relative;
    margin: 0;
    padding-bottom: 0;
    cursor: pointer;
  }

  &.big {
    .cu_info_description {
      left: 2em;
      right: 2em;
    }
  }

  &.small {
    padding: 0.7em;
    min-height: 100px;

    .cu_item_name {
      margin-top: 0;
      margin-bottom: 4px;
      font-size: 1.1em;
      color: $black;
    }

    .cu_info_description {
      bottom: -5px;
    }

    .cu_item_duration {
      margin: 0;
    }

    .cu_item_info {
      padding: 0 1em 1em;
      overflow: hidden;

      .cu_item_name {
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .cu_item_img {
      width: 144px;
    }
  }


  &.big {
    .cu_item_info_co {
      padding-bottom: 2em;
    }

    .cu_item_name {
      margin-bottom: .5em;
    }
  }

  .cu_item_info_co {
    color: $black;

    @include mobile {
      line-height: 0.8em;
    }

    .ui.image, svg {
      height: 2.5em !important;
      margin: 0;
    }

    .header {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      vertical-align: top;
      color: white;
      line-height: 2.35em;
      padding: 0 2em;
      margin: 0 -1.5em;
    }
  }

  .cu_item_duration {
    position: absolute;
    left: 0;
    z-index: 2;
    top: 0;
  }

  .cu_item_duration.rtl {
    right: 0;
    left: auto;
  }

  .cu_item_label {
    position: absolute;
    left: 0;
    z-index: 2;
    bottom: 0;
  }

  .cu_item_label.rtl {
    right: 0;
    left: auto;
  }

  .cu_item_img {
    position: relative;
    width: 100%;
    height: auto;
    @include mobile {
      width: 165px;
    }
  }

  .cu_item_info {
    padding: 1em 2em 0;
    position: relative;
    width: 100%;

    @include mobile {
      position: absolute;
      padding: 0;
      top: 1em;
      left: 173px;
      right: 0;
      width: auto;

      &.with_actions {
        right: 20px
      }
    }


    &.rtl {
      @include mobile {
        right: 173px;
        left: 0;

        &.with_actions {
          left: 20px
        }
      }
    }
  }

  .cu_info_description {
    margin: 0;
    height: 1.1em;
    position: absolute;
    bottom: 1em;
    opacity: 0.43;
    color: $black;
    right: 1em;
    left: 1em;
    width: max-content;
    min-height: 1.5em;

    @include mobile {
      top: 78px;
      bottom: auto;
      left: 0;
      right: 0;
      width: 100%;
    }
  }

  .cu_item_name {
    margin: 1em 0 2.5em;
    color: $black;
    display: inline-block;

    @include mobile {
      margin-top: 0;
      margin-bottom: 4px;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      max-height: 56px;
      color: $grey;
    }
  }
}

.cu_item_actions {
  position: absolute !important;
  right: -1em;
  top: 20%;

  .cu_item_dropdown {
    padding: 1em 0 1em 1em;

    .my_playlist_add {
      width: 100%;
      text-align: start;
    }

    &.ui.dropdown .menu > .item {
      color: rgba(0, 0, 0, .6);
    }

    & > .icon {
      color: $midGrey;
    }
  }
}

.rtl .cu_item_actions {
  right: auto;
  left: -1em;

  .cu_item_dropdown {
    padding-right: 1em;
    padding-left: 0;
  }
}

.text_item, .media_item {
  vertical-align: top;
  padding: 1em 0;
  border-bottom: 1px solid $midWhite;
  display: flex;
  flex-wrap: nowrap;

  &:last-child {
    border-bottom: none;
  }

  & > .image {
    margin-right: .5em;
    width: auto;


    .rtl & {
      margin-left: .5em;
      margin-right: 0;
    }

    svg {
      min-width: 50px;
      min-height: 50px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ui.header {
      font-size: 1.1rem;
      margin-bottom: 0;
      color: #134D78;

      & .sub.header {
        font-size: .9rem;
      }
    }

    .ui.container {
      width: 100%;
    }

  }

  .additional_links {
    margin-top: .5em;

    a {
      color: $black;
      padding: 0 .5em;
      line-height: .9em;
      border-right: 1px solid $midGrey;
      display: inline-block;

      &:first-child {
        padding-left: 0;

        .rtl & {
          border-right: none;
          padding-right: 0;
          padding-left: .5em;
        }
      }

      .ltr &:last-child {
        border-right: none;
      }

      @include mobile {
        border: none;
        padding: 4px 0;
        line-height: 1.1em;
      }
    }
  }

  &.daily_lesson .media_item__content {
    justify-content: flex-start;
  }

  &.daily_lesson .media_item__content {
    justify-content: flex-start;
  }

  .description {
    color: $midGrey;
    line-height: .9em;
    font-size: .9em;
    margin-top: 1em;


    span {
      opacity: 0.9;
      padding: 0 4px;
      margin: 2px 0;
      border-right: 1px solid $midGrey;
      display: inline-block;

      &:first-child {
        padding-left: 0;

        .rtl & {
          border-right: none;
          padding-right: 0;
          padding-left: 4px;
        }
      }

      &:last-child {
        border-right: none;

        .rtl & {
          border-right: 1px solid $midGrey;
        }
      }
    }

    &.is_single span {
      border: none !important;
    }
  }
}

.media_item {
  .media_item__content {
    padding: 0 1em
  }
}

.line_clamp_2_lines {
  -webkit-line-clamp: 2;
  display: -webkit-box !important;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.with_duration {
  position: relative;

  .duration {
    font-size: 0.8em;
    border-radius: 0;
    background-color: $black;
    color: white;
    margin: 4px;
    padding: 3px 4px;
    display: inline-block;


    position: absolute;
    left: 0;
    z-index: 2;
    top: 0;
    @include mobile {
      margin: 4px;
    }

    .ltr & {
      right: 0;
      left: auto;
    }
  }
}
