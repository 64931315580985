.pagination-wrapper {
  @include small-mobile {
    margin-top: -1px;
    padding: 0 !important;
  }
}

.pagination-menu {
  @include tablet {
    @for $i from 5 through 6 {
      .distance-#{$i} { display: none !important;}
    }
  }

  @include mobile {
    @for $i from 3 through 6 {
      .distance-#{$i} { display: none !important;}
    }
  }

  @include small-mobile {
    margin: -1px -1px 0 0!important;
    border-radius: 0!important;
    width: 100%;
    .item {
      border-radius: 0 !important;

      flex: 1 1 auto !important;
      padding: 0 !important;


      display: flex !important;
      justify-content: center;
      // text-align: right !important;
    }

    @for $i from 2 through 6 {
      .distance-#{$i} { display: none !important;}
    }

    .prev-page,
    .next-page {
      // display: none !important;
    }
  }
}
