//mixings
@mixin small-date {
  font-size: .9em;

  &::after {
    background-color: $darkWhite;
    content: '';
    display: block;
    height: 1px;
    margin: 4px 0;
    width: 1.5em;
  }
}

@import 'rtl/index';
//
.collection-header,
.section-header {
  background: $darkWhite;

  .ui.icon.header {
    margin: 0 !important;
  }

  h1.header {
    @include mobile {
      font-size: 2em;
    }
    @include small-mobile {
      font-size: 1.4em;
    }
    font-size: 3em;
    font-weight: 400;

    .sub.header {
      @include mobile {
        font-size: 1rem;
        margin: .2rem 0;
      }
      margin: .2em 0 .5em;

      &.section-header__subtitle2 {
        font-size: 1rem;
      }
    }
  }

  .menu {
    @include tablet {
      font-size: 1rem !important;
    }

    @include mobile {
      font-size: 1rem !important;
    }

    @include small-mobile {
      font-size: .92857143rem !important;
    }

    margin-bottom: calc(-1rem - 1px) !important;
    margin-top: -1rem !important;
  }
  .item {
    @include mobile {

      white-space: nowrap;
      overflow: hidden;
      padding: .928571em !important;

      border-radius: .28571429rem .28571429rem 0 0 !important;

      margin: 0 1px;
    }

    &:not(.active) {
      @include mobile {
        border-color: #d4d4d5 !important;
        border-width: 1px !important;
        flex: 0 1 auto;

        &::after {
          background-image: linear-gradient(to left, $darkWhite 20%, rgba($darkWhite, 0));
          content: '';
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          width: .928571em;
        }
      }
    }
  }
}

.index {
  @include small-mobile {
    .no-thumbnail {
      border: 0 !important;
      display: block !important;

      &:first-child {
        margin-top: -.785714em !important;
      }

      td {
        display: block;
        padding: 0 !important;
        white-space: normal !important;
        width: auto !important;

        &:first-child {
          &:not(:only-child) {
            margin-top: .785714em !important;
            padding-top: .785714em !important;
          }
        }

        &:last-child {
          border: 0 !important;
        }
      }
    }
  }

  &__date {
    @include small-mobile {
      @include small-date;
    }

    font-weight: bold;

    &:not(:only-child) {
      @include small-date;
    }
  }

  &__title {
    display: block;
    font-weight: bold;
  }

  &__item {
    @include small-mobile {
      padding: .785714em 0 0;
    }
    display: block;
  }

  &__thumbnail {
    @include small-mobile {
      width: 100px;
    }
    width: 120px;
  }

  &__description {
    font-size: .85714286em;
    margin: .2em 0 .5em;
    max-width: $max-readble-width;
  }

  &__collections {
    margin-top: 0 !important;
    vertical-align: top;

    .item:nth-child(2) {
      border-left: 0 !important;
      padding-left: 0 !important;
    }

    .item {
      line-height: 1 !important;
    }
  }
}
