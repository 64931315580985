@import 'rtl/filters';

.filters {
  &__menu {
    .item.alphabetFilter {
      flex: 1 1 auto !important;

      @include mobile {
        .item {
          width: inherit !important;
        }
      }
    }
  }

  &__header {
    align-self: stretch;
    flex: 0 1 auto !important;
    padding: .6em 1em !important;
    white-space: nowrap;
  }
}

.filter {
  align-items: flex-start !important;
  overflow: hidden;
  padding: 0 !important;

  &::after {
    background: rgba($black, 0);
    content: '';
    transition: background .2s ease-out;
  }

  &--is-empty {
    flex: 0 0 auto !important;
  }

  &--is-active {
    .filter__wrapper {
      z-index: 2;
    }

    .label {
      z-index: 3;
    }

    &::after {
      background: rgba($black, .5);
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &__wrapper {
    background: $white;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: .6em 1em !important;
    width: 100%;
  }

  &__title {
    margin-bottom: .7em;
  }

  &__state {
    display: flex;
  }

  &__text {
    @include mobile {
      width: auto;
    }

    display: flex;
    overflow: hidden;
    width: calc(100% - 1em);
  }

  &__fold-icon {
    flex: 0 0 1em;
    margin: 0 0 0 4px !important;
  }

  &__breadcrumb {
    &:last-child {
      flex: 0 1 auto;
      min-width: 1em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__separator {
    flex: 0 0 auto;
    font-weight: 900;
    padding: 0 .2em;
  }

  .label {
    @include mobile {
      left: calc(100% + 1px);
    }

    border-radius: 0 !important;
    bottom: 100%;
    left: 100%;
    margin: 0 !important;
    position: absolute;
    text-align: center;
    transform: translate(-100%, calc(100% - 1px));
    // z-index: 100;

    .icon {
      margin: 0;
    }
  }
}

// Filter popups

.filter-popup {
  @include mobile {
    width: calc(100% - 30px);
  }

  @include small-mobile {
    bottom: 0 !important;
    margin: .71428571em 0 !important;
    position: fixed !important;
    top: $main-menu-height !important;
  }

  overflow: hidden !important;
  padding: 0 !important;

  &__header {
    border-radius: 0 !important;

    .title {
      display: flex;

      .header {
        margin: 0 1rem;
        flex-grow: 2;
      }
    }

    .autocomplete {
      margin-top: .75em;
    }
  }

  &__content {
    @include small-mobile {
      height: 100%;
    }
  }

  &__wrapper {
    @include small-mobile {
      display: flex;
      height: 100%;
    }

    border: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }


  &__body {
    @include small-mobile {
      max-height: 100vh !important;
    }

    border-radius: 0 !important;

    .menu {
      border: 0;
      box-shadow: none !important;
      border-radius: 0 !important;
    }

    .item {
      color: $blue;

      .filter__count {
        color: $grey;
      }

      &.active {
        background: $blue !important;
        color: $white !important;

        .filter__count {
          color: $white !important;
        }
      }
    }
  }
}

.alphabetFilter {
  a.item {
    display: inline-flex !important;
    padding: 0 0.5em !important;
  }

  a.active {
    font-weight: bold !important;
  }
}

//aside filters styles
.filters-aside-wrapper {
  padding: 0 0 0 1em !important;

  .rtl & {
    padding: 0 1em 0 0 !important;
  }

  & > .container {
    background-color: $offWhite;
  }

  @include mobile {
    padding: 0 !important;

    & > .container {
      background: none;
      padding: 0 !important;
    }
  }
}

.filter_aside {
  border-bottom: 1px solid $midWhite;
  padding: 0 0 1em !important;
  margin: 0 !important;

  &:last-child {
    border-bottom: none;
  }

  & > .title {
    color: $blue;
    font-size: 1.3em;
    font-weight: bold;
    padding: 0.5em 0;

  }

  &:first-child > .title {
    padding-top: 0;
  }
  
  .date-filter-presets {
    display: flex;
  }

  .date-filter {
    min-width: auto;
    margin: .5em 0;
    padding: 0;

    .item {
      padding: 1em 0;

      .title {
        padding: 0;

        .icon {
          line-height: 1em;
        }
      }

      .content {

        width: fit-content;

        input {
          min-width: 200px;
        }

        .input .label {
          vertical-align: middle;
          line-height: 2em;
        }
      }
    }
  }

}

.ui.modal.filters_aside_tree_modal {
  text-align: end;
  width: auto;

  & > .header {
    display: flex;
    align-items: center;

    text-align: start;
    margin: 0;
    padding-top: 0;

    .search-input.ui.input {
      margin: 0 1em;
    }
  }

  & > .content {
    text-align: start;
    padding-bottom: 2.5em;
  }

  & > .icon {
    margin: 0.5em 0.5em 0;
    cursor: pointer;
  }

  .stat {
    margin-right: .5em;

    .rtl & {
      margin-left: .5em;
      margin-right: 0;
    }
  }

  .ui.basic.table {
    border: none;
  }
}

.tree_item_modal_content {
  @extend .no-shadow;

  .ui.table tr & {
    border-top: none;
  }

  .item .list {
    margin: 0 0 0 .5em;

    .rtl & {
      margin: 0 .5em 0;
    }

    .item {
      padding-bottom: 0;
    }
  }
}

.ui.cards > .card.single_item {
  margin: 0 .5em;
}

.filters_aside_tree_modal, .filter_aside {
  .search-input.ui.input {
    max-width: 180px;
    width: 100%;
    margin-bottom: 1em;

    & > input {
      padding: 2px 8px;
      border: 1px solid $blue !important;
      border-radius: 8px !important;
      font-size: 14px;
    }
  }

  .stat {
    opacity: .4;
    padding-left: 1em;
    margin-left: auto;

    .rtl & {
      padding-left: 0;
      padding-right: 1em;
      margin-left: 0;
      margin-right: auto;
    }
  }
}


.tree_item_content {
  display: flex;
  max-width: 80vw;  // Required for mobile. Limits column width by viewport.

  .tree_item_title {
    margin-left: 8px;

    .rtl & {
      margin-left: 0;
      margin-right: 8px;
    }
  }

  & > .icon {
    margin: 0 auto 0 0;
    font-size: 1.2em;
    line-height: 1em;

    .rtl & {
      margin: 0 0 0 auto;
    }
  }
}

.filter_aside_labels {
  margin-bottom: 1em;

  .ui.circular.label {
    margin: .5em .5em;
    padding: 2px 2px 0 .5em !important;
    cursor: pointer;

    .rtl & {
      padding-right: .5em !important;
      padding-left: 2px !important;
    }

    .icon {
      padding: 0 !important;
      opacity: .4;
      margin: 0 0 0 .5em;
      height: auto !important;
      width: auto !important;

      .rtl & {
        margin-left: 0;
        margin-right: .5em;
      }
    }
  }
}

.filter_aside_labels, .pagination-results {
  @include mobile {
    padding: 0 1em;
  }
}

.filters-aside-ct {
  font-weight: bold;

  .filters-aside-ct {
    font-weight: normal;
  }
}

