@import 'includes/colors-helpers';
@import 'includes/responsive-helpers';
@import 'includes/layout';
@import 'includes/text-helpers';
@import 'includes/common';
@import 'includes/index';
@import 'includes/unit';
@import 'includes/video-player';
@import 'includes/media-player';
@import 'includes/player';
@import 'includes/sources';
@import 'includes/search';
@import 'includes/homepage';
@import 'includes/pagination';
@import 'includes/filters';
@import 'includes/topics';
@import 'includes/bookmark';
@import 'includes/search-on-doc';
@import 'includes/trim';
@import 'includes/labels';
@import 'includes/notes';
@import 'includes/content-items';
@import 'gallery/image-gallery-no-icon';
@import 'includes/text-page';
@import 'includes/print';
@import 'includes/toc';
// @import 'gallery/image-gallery';
//////////ALL YOUR CSS IS HERE ↴
@import 'includes/not-sorted-scss';


.debug {
  background: yellow;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 999999999999;
}

// /// INDEX FILTERS
// .index-filters {
//   &__search {
//     .input {
//       top: .8em !important;
//       width: 300px !important;
//     }
//   }
// }

// /// END OF INDEX FILTERS

.filter_tab {
  .menu {
    border: 0 !important;
    border-bottom: 0 !important;
  }
}

.filter-steps {
  display: flex;
  height: 400px;
  margin-top: -1em;
  overflow: hidden;

  &__column-wrapper {

    border: 1px solid $border-color;
    border-width: 0 1px 0 0;
    max-width: 100%;
    overflow-y: scroll;

    &:first-child {
      flex: 0 0 auto;
      white-space: nowrap;
    }

    &:last-child {
      border: 0;
      flex: 1 1 auto;
    }
  }

  &__lists {
    .divider {
      margin: .2rem 0 0;
    }

    .list {
      margin-bottom: 2em !important;
    }

    .column > .header {
      color: $grey !important;
      margin-bottom: 1em;
      text-transform: uppercase;
    }

    .item {
      cursor: pointer;

      &:hover {
        background: rgba(0, 0, 0, .05);
      }
    }
  }

  .ui.menu {
    border: 0 !important;
    border-radius: 0;
    box-shadow: none !important;
    display: block;

    .item {
      border-radius: 0 !important;
    }
  }
}

.filter-tags {
  .buttons {
    margin-left: 4px;
    margin-right: 4px;
  }
}

.filter-popup.mobile {
  transform: none !important;
}
