.toc {
  background: $white;
  padding: 1em;
  position: fixed;
  height: calc(100VH - $main-menu-height - $breadcrumb-height);
  bottom: 0;
  z-index: 5;
  transition: all 0.2s ease-in-out 0s;
  border: 1px solid $stroke-gray;
  width: 360px;
  transform: translate3d(-360px, 0, 0);

  .rtl & {
    transform: translate3d(360px, 0, 0);
  }

  .is-dark & {
    background: $dark-bg !important;
    color: $dark-text !important;
    border-color: $dark-stroke;

    .ui.accordion .title:not(.ui) {
      color: $dark-text !important;
    }
  }

  .is-sepia & {
    background: $sepia-bg !important;
    color: $sepia-text !important;
    border-color: $sepia-stroke;

    .ui.accordion .title:not(.ui) {
      color: $sepia-text !important;
    }
  }

  &.toc_active {
    transition: all 0.2s ease-in-out 0s;
    transform: translate3d(0, 0, 0) !important;


    &.toc_selected {
      height: calc(100VH - $main-menu-height);
    }

    &.toc_scroll_up {
      height: 100VH;
      transition: height 0.2s ease-in-out 0s;
    }

    &.toc_scroll_down {
      height: calc(100VH - $main-menu-height - $breadcrumb-height);

      &.toc_selected {
        height: calc(100VH - $main-menu-height);
      }
    }

    .toc_scroll {
      overflow: hidden auto;
      height: calc(100% - 130px);
      position: relative;
    }

    @include mobile {
      width: 100%;
      position: fixed;
      bottom: auto;
      transform: translate3d(-100%, 0, 0);
      height: 100%;
      min-height: 100VH;
      z-index: 1000;
      top: 0;
      transition: height 0.2s ease-in-out 0s;


      .toc_scroll_align {
        display: flex;
        align-items: flex-start;
        height: fit-content;
        min-height: 100%;

        .is-mobile & {
          align-items: flex-end;
        }
      }


      .rtl & {
        transform: translate3d(100%, 0, 0);
      }
    }

    .is-dark & .toc_scroll {
      &::-webkit-scrollbar-thumb {
        background: $grey;
      }

      &::-webkit-scrollbar-track {
        background: $midWhite;
      }
    }
  }

  .accordion {
    .accordion {
      margin: 0;
    }

    .title {
      display: flex;
      flex-wrap: nowrap;

      .material-symbols-outlined {
        color: #005697;
      }

      &.active {
        font-weight: bold;

        .material-symbols-outlined {
          transform: rotate(-90deg);

          .ltr & {
            transform: rotate(90deg);
          }
        }
      }
    }

    .active.content {
      padding: 0;
      margin-right: 10px;

      .ltr & {
        margin-right: 0;
        margin-left: 10px;
      }
    }

    &.toc_last_level {
      border-right: 2px solid #2185D0;
      padding-right: 2em;

      .ltr & {
        border-right: none;
        padding-right: 0;
        border-left: 2px solid #2185D0;
        padding-left: 2em;
      }
    }

    &.toc_single_level > .title {
      border-bottom: 1px solid rgba(0, 86, 151, 0.1);
      padding: 16px 0;
      cursor: pointer;

      @include theme_stroke;
    }
  }

  .player_page_tab & {
    position: absolute;
    height: 100%;
    transform: translate3d(-380px, 0, 0);

    .rtl & {
      transform: translate3d(380px, 0, 0);
    }
  }
}

.toc_filter {
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 0;
  @include theme_bg;

  .is-mobile & {
    position: sticky;
    bottom: 0;
  }

  .ui.input.toc_search {
    width: 100%;
    @include theme_bg;

    > input {
      border-color: $blue;
      background: none;
      font-size: 18px;
      padding: 8px;

      &:active {
        background: none;
      }

      &, &::placeholder {
        @include theme_color;
      }

      &:focus {
        border-color: $light-active-text;

        &::placeholder {
          visibility: hidden;
        }
      }
    }

    .icon {
      color: $blue;
      opacity: 1;
      font-size: 18px;
    }
  }

  .toc_sort_btn.ui.icon.button {
    padding: 8px;
    background: none !important;
    border: 1px solid $blue;

    &.active {
      border-color: $light-active-text;

      .material-symbols-outlined {
        color: $light-active-text;
      }
    }

    .material-symbols-outlined {
      color: $blue;
    }
  }

  .divider {
    width: 1em
  }
}

.toc_control {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin-bottom: 1.5em;

  .button {
    display: flex;
    align-items: center;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin: 0 5px;
    }
  }

  .button span {
    color: $light-text;

    .is-dark & {
      color: $dark-text;
    }

    .is-sepia & {
      color: $sepia-text;
    }
  }
}