.header_search.ui.segment {
  border-radius: 0;
}

.media_item .media_item {
  padding: 0;
}

.media_item .content {
  width: 100%;
}

.media_item__logo .icon {
  width: 144px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -10px;
  margin-bottom: -6px;

  span {
    text-align: center;
    font-weight: bold;
    font-size: 1.3em;
  }
}

.search__cards {
  position: relative;
}

.ui.button.scroll_tweets {
  position: absolute;
  bottom: 0;
  background-color: #fff !important;
  z-index: 10;
}

.search {
  &__results.ui.divided.list > .item:first-child {
    border-top: 1px solid rgba(34, 36, 38, .15);
  }

  &__results.ui.divided.list > .item {
    border-right: 1px solid rgba(34, 36, 38, .15);
    border-left: 1px solid rgba(34, 36, 38, .15);

    padding: 1em;
  }
}

.media_item {
  .bg_transparent {
    background: transparent;
  }

  .no-padding {
    padding: 0 !important;
  }
}

.score_debug {
  position: absolute;
  right: 300px;
}
