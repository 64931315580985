@import 'rtl/sources';

// Library index - Collections
.sources {
  &__authors {
    height: 100%;

    tr,
    tbody {
      @include mobile {
        height: auto;
      }

      height: 100%;
    }

    .image {
      @include mobile {
        margin: 0 0 1em;
      }

      @include small-mobile {
        margin: 0 auto 1em;
      }
    }

    .author {
      &--image {
        td {
          height: 100%;

          &:nth-child(2) {
            > div {
              height: 100%;
              overflow: auto;
              position: relative;
            }
          }
        }

        .sources__list {
          @include mobile {
            position: static;
          }

          position: absolute;
        }

        .list {
          @include mobile {
            position: static;
          }

          position: absolute;
        }
      }
    }
  }

  &__list {
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    left: 0;
    right: 0;
    top: 0;

    > div:nth-child(2) {
      flex: 1 1 auto;
      overflow: hidden;
      position: relative;
    }

    .list {
      @include mobile {
        margin-top: -.214286em !important;
      }

      align-content: flex-start;
      bottom: 0;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      left: 0;
      right: 0;
      top: 0;

      .item {
        @include mobile {
          width: 50%;
        }

        @include small-mobile {
          width: 100%;
        }

        @include tablet {
          width: 50%;
        }

        padding: .214286em 0 !important;
        width: 33.333333333%;
      }
    }
  }
}

// Library item - Unit
.en_styles {
  font-family: Georgia, “Times New Roman”, Times, serif;
  font-size: 1em;

  h1 {
    font-size: 2em;
    text-align: center;
    font-weight: bold;
    font-family: inherit;
  }

  h2 {
    font-size: 1.6em;
    text-align: center;
    font-weight: bold;
    font-family: inherit;
  }

  h3 {
    font-size: 1.2em;
    line-height: 1.6em;
    text-align: justify-all;
    margin: 0 5em;
    font-family: inherit;
  }

  h4 {
    font-size: 1em;
    line-height: 1.4em;
    text-align: justify-all;
    font-family: inherit;
  }

  h5 {
    font-size: 1em;
    line-height: 1.4em;
    text-align: center;
    margin: 0 5em;
    font-family: inherit;
  }

  h6 {
    font-size: 1.3em;
    line-height: 1.6em;
    text-align: justify-all;
    font-family: inherit;
  }
}

.ru_styles {
  font-family: Helvetica, sans-serif;
  font-size: 1em;
  text-align: left;
  font-weight: normal;

  strong {
    font-weight: bold;
  }

  h1 {
    font-size: 2.5em;
    font-family: inherit;
    font-weight: bold;
    padding: 0;
    margin: 0 0 1em 0;
  }

  h2 {
    font-size: 2em;
    font-family: inherit;
    font-weight: 400;
    padding: 0.5em 0 1.5em 0;
    margin: 0 0 2em;
    border-bottom: 1px solid $grey;
  }

  h3 {
    font-size: 1.2em;
    line-height: 1.6em;
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    padding: 0 0 2em 0;
  }

  h4 {
    font-size: 1.2em;
    font-family: inherit;
    font-weight: 500;
    margin: 0 0 1.5em;
  }

  h5 {
    font-size: 1.2em;
    line-height: 1.4em;
    margin: 0 0 2em;
    font-family: inherit;
  }

  h6 {
    font-size: 1.2em;
    line-height: 1.4em;
    padding: 0 0 2em 2em;
    margin: 0;
    font-family: inherit;
  }

  h7 {
    font-size: 1em;
    font-weight: bold;
    font-family: inherit;
  }

  p {
    font-size: 1em;
    line-height: 1.6em;
    font-family: inherit;
    font-weight: 300;
  }
}


.source_prev_next {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  
  @at-root .is-mobile & {
    justify-content: center;
    gap: 5px;
  }
  
}

.prev-next-btn.ui.button.basic{
  margin: 0 ;
  padding: 0 ;
  display: flex;
  color:$blue !important;
  border: 1px solid $blue ;
  
  &:hover{
    color:$light-hover-btn !important;
    border:1px solid $light-hover-btn !important;
  }
  
  &:active{
    color:$light-active-text !important;
    border:1px solid $light-active-text !important;
    background-color: $white !important;
  }
  
  @at-root .is-dark & {
    background-color:$dark-bg !important; 
    color: $blue !important;
    border:1px solid $blue;
    
    &:hover{
      color:$dark-btn-hover !important;
      border:1px solid $dark-btn-hover  !important;
    }
    
    &:active{
      color:$dark-active-text !important;
      border:1px solid $dark-active-text  !important;
    }
  }
  
  @at-root .is-sepia & {
    background-color:$sepia-bg !important; 
    color: $blue !important;
    border:1px solid $blue;
  }
  
}

.btn-content{
  display: flex;
  font-weight: 500;
  font-size: 16px;
  line-height: 15px;
  align-items: center;
  gap:2px;
  
   &.left {
    padding:11px 32px 11px 23px ;
  }
   &.right {
    padding: 11px 23px 11px 32px;
  }
  
  @at-root .is-mobile & {
    padding:6.5px 24px 6.5px 24px ;
  }
}

.prev-next-btn-icon{
  margin: 6px 8px 5px 8px !important;
  width:8px !important;
  
  &::before {
    content: ''; 
    display: inline-block; 
    width: 7.41px; 
    height: 12px; 
  } 
}
