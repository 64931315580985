.select_topic_modal {
  & > .header {
    text-align: center
  }

  .scrolling.content {
    height: 50VH;
  }

  .input {
    font-size: 1.3rem;
    font-weight: normal;

    .label {
      font-size: 1.2rem;
      font-weight: normal;
    }

    input {
      padding: 4px 8px;
    }
  }

  .search-omnibox {
    width: auto;

    input {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }

  .topic_row_title {
    margin: 0 !important;
    padding: 1rem !important;
    border-bottom: 1px solid $grey;
    font-weight: normal;
  }

  .topics_card {
    margin: 1em 1em 2em;
    background: #F3F4F5;
    color: $grey;
  }

  .topics_title {
    box-shadow: 0px 3px 6px $grey;
    padding: 4px 1em;
    margin: 0 0 8px;
    color: $blue;
  }

  .label_topic_grid {
    padding-top: 0 !important;

    .ui.grid {
      & > .row {
        & > .column {
          padding: 0
        }
      }
    }
  }

  &.ui.modal > .label_topic_grid.content {
    padding-top: 0 !important;
  }
}

.label_marks {
  height: 100%;
  position: relative;
  width: 20px;

  .label_mark {
    position: absolute;
    width: 0;
    border-left: 1px solid $blue;
    left: 0;
    vertical-align: middle;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .button {
      z-index: 1;
    }

    svg {
      width: 20px;
      height: 20px;
    }
  }

}

.ui.popup.label_mark_popup {
  opacity: 0.95;

  .link_to_cu {
    padding: 2px 3px !important;
    font-size: smaller;
    background: $midGrey !important;
    color: white !important;

    &:hover {
      background-color: $sepia-bg;
    }
  }
}

.ltr .label_marks {
  right: auto;
  left: -10PX;
}
