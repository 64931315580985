.rtl {
  .layout {
    &__sidebar {
      left: auto;
      right: 0;
      transform: translate(100%, 0);

      &-menu {
        border-left: 1px solid $border-color;
        border-right: 0;
      }
    }
  }

  .logo {
    h1.header {
      margin-left: 0;
      margin-right: 16px;
    }
  }

  @media only screen and (min-width: $largeMonitorBreakpoint) {
    .layout:not(.is_fullscreen) {
      .layout__sidebar {
        transform: none;
      }

      .layout__main {
        margin-left: 0;
        margin-right: $sidebar-width;
      }

      .layout__secondary-header {
        left: 0;
        right: $sidebar-width;
      }
    }
  }
}
