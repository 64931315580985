@import '../includes/colors-helpers';
// See TimedPopup.js, solves existing bug:
// https://github.com/Semantic-Org/Semantic-UI-React/issues/1065
// .popup {
// background: #fff;
// border: 1px solid #d4d4d5;
// border-radius: .28571429rem;
// box-shadow: 1px 1px 0 0 #bababc;
// color: rgba(0, 0, 0, .87);
// font-style: normal;
// font-weight: 400;
// line-height: 1.4285em;
// margin: 0 0 .71428571em;
// max-width: 250px;
// padding: .833em 1em;
// z-index: 1900;

// .content {
// text-align: center;
// width: 200px;
// }
// }

.search-omnibox {
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  input {
    border: 1px solid #7dcff5 !important;
    border-radius: 20px !important;
  }

  // main omnibox size
  .input {
    width: 100%;
  }

  > .results {

    &.visible {
      /* Limit suggest box height and allow scrolling if more then ~10. */
      max-height: 500px;
      overflow-y: scroll;
    }

    // this falls sub-pixel apart from the category name table-cell,
    // it doesn't look good. so we cancel it
    > .category {
      > .name {
        border-bottom: none !important;
      }

      .result {
        background-color: $white;

        //border-bottom: none !important;
        &:last-child {
          border-bottom: 1px solid rgba(34, 36, 38, .1) !important;
          margin-bottom: 1px;
        }

        &:hover {
          //background-color: rgba($blue, .075) !important;
          background-image: linear-gradient(to bottom, rgba($blue, 0.125), rgba($blue, 0)) !important;
        }
      }

      &:last-child {
        .result:last-child {
          border-bottom: none !important;
          margin-bottom: 1px;
        }
      }
    }
  }
}

.search-result-rtl {
  .title {
    direction: rtl;
    display: inline-block;
  }
}

/* Make hightlights in search also bold (not only italic).*/
.media_item em {
  font-weight: bold;
}

/* Do not display images in doc files converted to html as they are broken */
.doc2html {
  p {
    padding: 0 1em;
  }

  h1,
  h2 {
    padding: 0 0.5em;
  }

  img {
    display: none;
  }
}

.doc2html-dropdown-container {
  margin-bottom: 10px;
}

/* Program filters hack (rename, move to filters)*/
.filter-letters {
  flex-direction: column !important;
  flex-wrap: wrap !important;
  height: 600px !important;
  width: auto !important;
}

/* space between groups in playlist (events) */
// .avbox__playlist-view-section {
//   padding-bottom: 2rem !important;
// }

/* application loader screen */
#app-loader {
  width: 100vw;
  height: 100vh;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    color: black;
  }
}

/* hide iPhone native controls - see iphone-inline-video */
.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
  opacity: 0;
  pointer-events: none;
  width: 5px;
}

/* limit the height of the media player languages dropdown in order to not get out of the screen */
.mediaplayer__languages .ui.floating.dropdown > .menu {
  max-height: 220px;
}

.avbox__playlist-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;

  .content {
    width: 100%;
  }

  .sub {
    white-space: nowrap;
  }

  .dateButton,
  .dateButton:hover,
  .dateButton:active,
  .dateButton:focus {
    color: white;
    background-color: transparent;
    margin: 0;
    font-size: 1.1rem;
    font-weight: normal;
    padding-right: 0.5em;
    padding-left: 0.5em;

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }

    .icon {
      opacity: 1 !important;
    }

    @include mobile {
      & .icon {
        font-size: 1rem;
        line-height: 0.8rem
      }
    }
  }
}

// filters redesign
// .index-filters {
//   margin-top: .5rem !important;
//   .item {
//     &.header {
//       padding: .95714286em 1.14285714em !important;
//     }
//     padding: .35714286em 1.14285714em !important;
//     .header {
//       margin: 0;
//       .sub {
//         color: $blue;
//       }
//     }
//   }
// }

.filter-popup {

  &__body {
    max-height: 350px;
    min-width: 300px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.segment {
      padding: 0;
    }

    .highlight {
      background-color: rgba($yellow, 0.2);
    }

    &.date-filter {
      max-height: 450px;

      .DayPickerInput {
        display: block;
      }

      .DayPicker-Caption > div {
        font-size: 1rem;
      }
    }

    &.sections-filter {
      img {
        margin-bottom: .2em;
        width: 50px !important;
      }

      .row .center.aligned.column {
        display: flex !important;
        justify-content: center;
        align-items: stretch;
      }

      .header {
        flex: 1 1 auto;
        border-radius: 4px;
        padding: 4px 4px 8px !important;

        &:hover {
          background-image: linear-gradient(to bottom, $darkWhite, rgba($darkWhite, 0));
        }

        &.active {
          background-image: linear-gradient(to bottom, rgba($blue, 0.3), rgba($blue, 0));
        }
      }
    }
  }

  .ltr {
    .hierarchy {
      .item {
        &.l1 {
          padding-left: 1rem;
        }

        &.l2 {
          padding-left: 2rem;
        }

        &.l3 {
          padding-left: 3rem;
        }

        &.l4 {
          padding-left: 4rem;
        }

        &.l5 {
          padding-left: 5rem;
        }

        &.l6 {
          padding-left: 6rem;
        }
      }
    }
  }

  .rtl {
    .hierarchy {
      .item {
        &.l1 {
          padding-right: 1rem;
        }

        &.l2 {
          padding-right: 2rem;
        }

        &.l3 {
          padding-right: 3rem;
        }

        &.l4 {
          padding-right: 4rem;
        }

        &.l5 {
          padding-right: 5rem;
        }

        &.l6 {
          padding-right: 6rem;
        }
      }
    }
  }
}

.field.slice-button-mobile input {
  font-size: 16px !important;
}

.ui.column.grid > [class*="eight wide"].column,
.ui.grid > .column.row > [class*="eight wide"].column,
.ui.grid > .row > [class*="eight wide"].column,
.ui.grid > [class*="eight wide"].column {
  width: 70% !important;

  @include mobile {
    width: 100% !important;
  }
}

.collection-header {

  .ui.column.grid > [class*="four wide"].column,
  .ui.grid > .column.row > [class*="four wide"].column,
  .ui.grid > .row > [class*="four wide"].column,
  .ui.grid > [class*="four wide"].column {
    width: 20% !important;

    @include mobile {
      width: 30% !important;
    }
  }

  .ui.column.grid > [class*="eight wide"].column,
  .ui.grid > .column.row > [class*="eight wide"].column,
  .ui.grid > .row > [class*="eight wide"].column,
  .ui.grid > [class*="eight wide"].column {
    @include mobile {
      width: 70% !important;
    }
  }
}

.collection-header {
  .social-buttons {
    position: relative;
    background: rgba(27, 28, 29, 0.8);
    border-radius: calc(49px / 2 + .5rem);
    margin: .6rem -.3rem;
    padding: 0.25rem;

    .icons {
      position: absolute;
      top: -3px;
      left: -6px;
    }
  }
}

.publications-twitter {
  max-width: 550px !important;
}

.tweet {
  margin-bottom: 1.5rem !important;

  &--username {
    color: rgba($grey, .75);
  }

  &--media {
    max-width: 100%;
    height: auto;
  }
}

.blog-posts {
  max-width: 550px;

  .read-more-link {
    padding-bottom: 0 !important;
  }
}

.post {
  margin-bottom: 1.5rem !important;
  background: #FFF;
  padding: 1em;

  /* ********** wordpress style ********** */

  img.userpic,
  img.ngg-singlepic {
    float: left;
    padding: 0 5px 5px 0;
    border: 0 none;
  }

  .a-title {
    display: block;
    padding: 0;
    margin: 0 0 0.5ex 0;
    line-height: 10px;
    font-size: 10px;
    font-weight: bold;
    color: #000080;
  }

  .a-link {
    padding: 0;
    margin: 0 0 -1.0ex;
    line-height: 10px;
    font-size: 10px;
  }

  .a-link-first {
    padding: 0;
    margin: 0;
    line-height: 10px;
    font-size: 10px;
  }

  .a-link-last {
    display: block;
    padding: 0;
    margin: 0 0 2.4ex 0;
    line-height: 10px;
    font-size: 10px;
  }

  .entry p {
    margin: 0 0 0.6ex 0;
  }

  .entry .bb-p {
    margin-top: 10px;
  }

  .entry ul,
  .entry ol {
    margin: 0 0 15px 40px;
  }

  .entry ul {
    list-style-type: square;
  }

  .entry li {
    margin: 0 0 5px 0;
  }

  .entry ul li ul,
  .entry ul li ol,
  .entry ol li ul,
  .entry ol li ol {
    margin: 5px 0 5px 30px;
  }

  .entry img {
    //max-width: 445px;  // laitman.ru
    max-width: 100%;
    border: 0 none;
  }

  .entry img.left {
    padding: 6px;
    margin: 0.5em 15px 0.5em 0;
    border: 1px solid #ccc;
    background: #eee;
    float: left;
    clear: left;
  }

  .entry img.right {
    padding: 6px;
    margin: 0.5em 0 0.5em 15px;
    border: 1px solid #ccc;
    background: #eee;
    float: right;
    clear: right;
  }

  .entry img.center {
    display: block;
    padding: 8px;
    margin: 0 auto 1.5em auto;
    border: 1px solid #ccc;
    background: #eee;
    float: none;
    clear: both;
  }

  .entry img.off {
    padding: 0 !important;
    border: none !important;
  }

  .entry img.stack {
    clear: none !important;
  }

  .entry {
    font-size: 13px;
    line-height: 16px;
    text-align: justify;
  }

  .entry a {
    text-decoration: underline;
  }

  .sub-pages ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  .bborder {
    border-bottom: 1px solid black;
  }

  .clear {
    clear: both;
    height: 1px;
    font-size: 1px;
    padding: 5px 0 0;
  }

  .ngg-imagebrowser {
    clear: both;
    padding-top: 10px;
  }

  .ngg-imagebrowser img {
    margin: 0 auto;
  }

  .ngg-imagebrowser h3 {
    display: none;
  }

  .ngg-imagebrowser .ngg-imagebrowser-desc {
    clear: both;
  }

  .entry .ngg-gallery-thumbnail img {
    margin: -6px 0 0 -6px;
  }

  .entry .ngg-gallery-thumbnail {
    padding: 0;
  }

  .warning {
    background-color: #CFEBF7;
    border: 1px solid #CC0000;
    margin: 5px 15px 2px;
    padding: 0 0.6em;
  }

  table {
    margin: 0 0 10px;
  }

  .podPressPlayerSpace {
    position: absolute;
  }

  .entry table td {
    clear: both;
  }

  .aligncenter,
  div.aligncenter {
    text-align: center;
  }

  .ngg-gallery-thumbnail-box {
    float: left;
    margin-top: 20px;
    margin-left: 6px;
  }

  p img {
    padding: 0;
    max-width: 100%;
  }

  /*	Using 'class="alignright"' on an image will (who would've
      thought?!) align the image to the right. And using 'class="centered',
      will of course center the image. This is much better than using
      align="center", being much more futureproof (and valid) */

  img.centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  img.alignright {
    padding: 4px;
    margin: 0 0 2px 7px;
    display: inline;
  }

  img.alignleft {
    padding: 4px;
    margin: 0 7px 2px 0;
    display: inline;
  }

  .alignright {
    float: right;
  }

  .alignleft {
    float: left
  }

  // normalize laitman.co.il headings in entry
  .entry {

    h1,
    h2,
    h3,
    h4 {
      font-size: 1rem;
    }
  }

  /* ********** wordpress style ********** */
}

// native day picker (date filter && Simple mode for mobile)
.to-from-label {
  min-width: 70px;
}

.hide-native-date-input {
  opacity: 0;
  position: fixed;
  top: 40px;
  height: 0;
  font-size: 16px;
}

.DayPicker-Day {
  outline: none !important;
}

/* ************* Styles for simple mode ************* */
.no-padding-top {
  @media only screen and (max-width: $mobileBreakpoint) {
    padding-top: 0;
  }
}

.stick-calendar {
  position: sticky;
  top: 65px;
}

.summary-container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1em;

  &.adjust-height {
    height: 2.68em;
    align-items: center;
  }

  .controller {
    display: flex;
    align-items: center;

    h4 {
      margin: 0 6px 0 0;
    }

    span {
      margin: 0 6px;
    }

    button {
      padding: 0;
      border: 0;
      box-shadow: none;
      color: #1a4179;
      background: transparent;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }

      &.disabled {
        color: #b1b1b1;
        text-decoration: none;
        cursor: auto;
      }
    }

    .dropdown-container {
      width: auto;
      line-height: 1;
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    .controller {
      flex-direction: column;
      margin-top: 1rem;

      &:first-of-type {
        padding-right: 4px;
      }

      .date-container {
        display: flex;

        input {
          background: #ebecf0;
          color: #1a4179;
          display: inline-block;
          width: 90px;
          height: 0;
          margin: 0 .5em;
          max-width: max-content;
          box-sizing: content-box;

          &:focus {
            outline: none;
          }
        }
      }

      .dropdown-container {
        width: 90px;

        select {
          padding: 2px;
          box-shadow: none;
          background: #ebecf0;
          color: #1a4179;
          border: 1px solid rgba(34, 36, 38, 0.15);
          border-radius: .28571429rem;
          box-sizing: content-box;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.unit-link {
  color: #4183c4 !important;
}

.horizontal-list {
  display: flex;
  padding-bottom: 16px !important;
  border-bottom: 1px solid #ebecf0;
  text-decoration: none !important;
  overflow-x: auto;

  &.remove-bottom-border {
    border: 0;
  }

  &::-webkit-scrollbar {
    display: none;
    width: 0;
    background: transparent;
  }

  .no-files {
    display: inline-flex !important;
    align-items: center;
    flex-wrap: nowrap;
    font-size: 14px;
    font-weight: normal;
    color: #333333;
    padding: 10px 24px 10px 16px !important;
    border: 1px solid #7dcff5;
    border-left-width: 4px;
    border-radius: 4px;
    background-color: rgba(125, 207, 245, 0.16);

    .content {
      white-space: pre-wrap;

      .bold-font {
        font-weight: bold;
      }
    }

    img {
      width: 20px;
      height: 20px;
    }

    .choose-language-button {
      background: transparent !important;
      height: auto !important;
      padding: 0 !important;
      margin: 0 !important;
      text-decoration: underline;
      color: #2185d0;
    }
  }

  .media-file-button {
    height: 24px;
    margin-right: 8px;
    text-decoration: none;
    display: flex !important;
    padding: 0 8px !important;
    justify-content: center;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    color: #fff;
    background-color: #ef702e;
    font-size: 12px;
    flex-shrink: 0;

    .file-list-icon {
      width: 12px;
      margin-left: 5px;
    }

    a {
      color: #fff !important;
      font-weight: normal !important;
    }

    &:hover,
    &:hover .icon,
    &:hover a {
      color: #fff !important;
    }
  }
}

.unit-header {
  font-weight: 300 !important;
  font-size: 18px;
  margin: 12px 0 !important;
  color: #1a4179 !important;

  &.no-margin {
    margin: 0 !important;
  }
}

.duration {
  color: $midGrey;
  font-size: 14px;
  margin: 0 1em;
  display: inline-block;
}

.under-line {
  text-decoration: underline;
}

.hover-under-line:hover {
  text-decoration: underline;
}

.gray-header {
  background-color: #ebecf0 !important;
  padding: 0.2em 1em !important;
}

.type-header-top-margin {
  margin-top: 45px;
}

.simple-mode-type-icon {
  display: inline-block !important;
  width: 32px;
  margin-right: 5px;
}

.inline-button {
  width: 80px !important;
  margin: 5px !important;
}

.rtl {
  .summary-container {

    .controller:first-of-type {
      padding-left: 4px;
    }

    h4 {
      margin: 0 0 0 6px;
    }
  }

  .horizontal-list {
    display: flex;

    .media-file-button {
      margin-left: 8px;
      margin-right: 0;

      .file-list-icon {
        margin-left: 0;
        margin-right: 5px;
      }
    }

    .no-files {
      padding: 10px 16px 10px 24px !important;
      border-width: 1px 4px 1px 1px;
    }
  }

  .simple-mode-type-icon {
    margin-left: 5px;
    margin-right: 0;
  }
}

.mediaplayer__onscreen-controls {
  .player-button.player-error-message {
    background: white;
    border: 2px solid red;
    padding: 7px;
    border-radius: 50px;
  }
}

.mediaplayer__unmuteButton {
  position: absolute;
  top: 5px;
  left: 5px;
}

.mediaplayer__unmuteButton.rtl {
  left: inherit;
  right: 5px;
}

.mediaplayer__embedUnmuteButton {
  z-index: 10000;
  position: absolute;
  top: 20px;
  left: 5px;
}

.mediaplayer__embedUnmuteButton.rtl {
  left: inherit;
  right: 5px;
}

.mediaplayer__mobileLoader {
  position: absolute;
  top: 30%;
  left: 40%;
}

/* ************* End of styles for simple mode ************* */

/* ************* Start of styles for homepage social media section ************* */
.home-social-section {
  .titles {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 25px;

    h4 {
      margin-bottom: 0;
      color: #134D78;
      font-size: 1.6em;
      margin-right: 0.2em;

      @include mobile {
        font-size: 1.5em;
      }
    }

    a {
      flex-grow: 0;
    }
  }

  .read-more-bottom {
    width: 100%;
    text-align: center;
    padding: 15px;
  }
}

.home-blog-posts {

  .blog-posts {
    max-width: 100%;

    .post:last-of-type,
    .divider:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  .remove-indent {
    padding-left: 0 !important;
  }

  .entry {
    position: relative;

    * {
      display: none;
      color: #828282;
      font-weight: 400;
    }

    *:first-of-type {
      display: inline;
      width: auto;
    }

    img,
    style,
    .bwg_container {
      display: none !important;
    }

    .date {
      font-weight: bold;
      color: #000;
    }
  }

  .read-more-link {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
  }
}

.home-twitter {
  background-color: #F4F9FB;

  .twitter-avatar {
    border-radius: 50% !important;
    width: 45px !important;
    height: auto !important;
  }

  .tweet-title-wrapper {
    display: flex;
    align-items: center;

    .tweet-title {
      display: inline-flex;
      flex-direction: column;
    }
  }

  .ui.feed {
    margin-bottom: 0 !important;
  }

  .tweet-friendly-date {
    text-align: right;
    color: rgba(118, 118, 118, 0.75);
    margin-top: 5px;
  }

  .divider {
    margin-bottom: 25px !important;

    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
}

.rtl {
  .home-blog-posts {
    .remove-indent {
      padding-right: 0 !important;
      padding-left: 4px !important;
    }
  }

  .tweet-friendly-date {
    text-align: left;
  }
}

/* ************* End of styles for homepage social media section ************* */

.dropdown-language-selector {
  border: none;
}

.dropdown-language-selector * {
  line-height: 1;
}

.dropdown-language-selector.blink,
.blink {
  box-shadow: 0 0 0 6px #7dcff5 inset;
  animation: dropdown-blink .8s ease-in-out;
  animation-iteration-count: 5;
  animation-delay: .7s;
}

@keyframes dropdown-blink {
  0% {
    box-shadow: 0 0 0 5px #7dcff5 inset;
  }

  100% {
    box-shadow: none;
  }
}

.language-trigger {
  cursor: pointer;
}

.language-close {
  position: absolute;
  top: 0.3em;
  right: 0.3em;
}

.language-item {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2em;
  margin-bottom: 0.2em;

  & .disabled {
    opacity: .5;
    color: #000;
  }
}

.language-first-item {
  border-top: 0 !important;
  margin-right: 0.4em;
}

.language-first-item .dropdown.icon {
  font-size: 1.5em !important;
}

.language-url {
  color: red;
  max-width: 16em;
}

.language-not-celled {
  padding-top: 1em !important;
  border-bottom: 0 !important;
  display: flex !important;
  justify-content: center;
}

.language-ui {
  display: flex;
  justify-content: space-around;
}

.content-languages-title {
  text-align: center;
  margin-top: 0.5em;
}

.ui.selection.dropdown .menu > .item.language-selection-divider {
  background-color: black !important;
  height: 2px !important;
  padding: 0 !important;
}

.language-subtext {
  font-size: 0.8em;
  max-width: 19em;

  &.disabled {
    opacity: .5;
    color: #000;
  }
}

.handle-language-header {
  &.title {
    display: flex;
  }

  .header {
    margin: 0 1rem;
    flex-grow: 2;
  }
}


@media screen and (-webkit-min-device-pixel-ratio: 0) {

  select,
  textarea,
  input {
    font-size: 16px;
  }
}

/* ************* Style for Semantic Ui Popup  ************* */
.change-popup-direction .content {
  direction: rtl;
}

/* ************* End of Style for Semantic Ui Popup  ************* */

.buttonLink {
  font-weight: bold;
  color: whitesmoke;
}

.FastDayPicker-DayPicker-NavButton {
  width: 17em;

  .DayPicker-NavButton {
    top: 0;
    float: right;
  }
}

.ui.flowing.popup {
  z-index: 1899;
}

.ui.accordion.menu .item .title > .dropdown.icon {
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.ui.menu .item > svg:not(.ui) {
  display: inline-block;
  vertical-align: middle;
  margin: -.3em 0;
  width: 2.5em;
}

.ui.top.left.popup {
  &.filter-popup {
    top: 0 !important;
    overflow: auto !important;
  }
}

.ui.top.right.popup {
  &.filter-popup {
    top: 0 !important;
    overflow: auto !important;
  }
}

.action:hover {
  background: inherit !important;
}

.searchButton {
  border-radius: 0 .28571429rem .28571429rem 0 !important;
  margin-right: 45px !important;
}

.dateButton {
  border-radius: .28571429rem !important;
}

@media only screen and (max-width: $tabletBreakpoint) {
  .search-omnibox {
    .searchButton {
      margin-right: 5px !important;
      padding-left: 15px !important;
      padding-right: 0 !important;
    }
  }

  .dateButton {
    padding-left: 8px !important;
    padding-right: 0 !important;

    &.dateButton_with_label {
      padding-right: 4px !important;
      margin: 0;
    }
  }

}

.rtl {
  .search-omnibox {
    .searchButton {
      border-radius: .28571429rem 0 0 .28571429rem !important;
      margin-right: 0 !important;
      margin-left: 45px !important;
      padding-right: 15px !important;
    }
  }

  @media only screen and (max-width: $tabletBreakpoint) {
    .search-omnibox {
      .searchButton {
        margin-right: 0px !important;
        margin-left: 5px !important;
      }
    }

    .dateButton {
      padding-left: 5px !important;
      padding-right: 8px !important;

      &.dateButton_with_label {
        left: 4px !important;
      }
    }
  }
}


.share-publication {
  margin: 0 5px;
}


//search on the page
.scroll-to-search {
  box-shadow: 1px 1px 5px 1px #7dcff5;
  animation: blink-on-scroll .8s ease-in-out;
  animation-iteration-count: 5;
  animation-delay: .7s;
  background: rgba(197, 221, 250, 0.1);
  padding: 5px;

  em._h {
    border-bottom: 1px solid rgba(65, 131, 196, .3);
  }

  em._b {
    font-weight: bold;
  }
}

@keyframes blink-on-scroll {
  0% {
    box-shadow: 1px 1px 5px 1px #7dcff5;
  }

  100% {
    box-shadow: none;
  }
}


.languagesGrid {
  margin-top: 0 !important;
}

.media-downloads {
  margin-top: 1em;
}


.library-language-container {
  padding-right: 0;
  padding-left: 0;
  margin-bottom: 1em;
  text-align: end;
}

.my_playlist_item {
  position: relative;

  .over_layer {
    position: absolute;
    top: 0;
    right: 0;
    width: 30%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1;
    color: white;
    display: flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    flex-flow: column;

    .header {
      margin: 0;
      color: white;
    }

    &.rtl {
      left: 0;
      right: auto;
    }
  }

  .unit-logo {
    width: 100%;

    @include mobile {
      max-width: 160px;
    }
  }
}

.weight-normal {
  font-weight: normal !important;
}

.vertical_bottom {
  vertical-align: bottom !important;
}


.homepage__thumbnails .my_header.ui.header {
  margin-right: 1.5em;
  margin-left: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  @include mobile {
    margin-right: 0;
    margin-left: 0;
  }
}

.my_header.ui.header {
  margin: 0 0 4px;
  font-weight: normal;
  vertical-align: bottom;

  &.my_playlist_header {
    margin-bottom: 14px;
  }

  svg {
    height: 1.5em;
    width: 1.5em;
    margin: 0 0 -.3em;

  }

  .icon {
    font-size: 1em;
    vertical-align: top;
    text-align: start;
  }
}

.my_playlist_actions {
  display: none;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  @include mobile {
    display: flex;
    margin-top: -15px;
  }
}

.playlist_icon {
  height: 40px;
  width: 38px;
}

.playlist_add {
  height: 25px;
  width: 28px;
}

.my_playlist_add, .my_tag {
  display: flex;
  flex-flow: nowrap;
  align-items: center;

  span {
    margin: 0;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
  }

  svg {
    vertical-align: middle;
  }
}

.cu_item:hover .my_playlist_actions {
  display: flex;
}


.my_playlist_header.ui.header {
  @include mobile {
    display: block;

    svg {
      vertical-align: bottom;
    }

    .content {
      vertical-align: bottom;
      margin: 0 8px;
    }

    .sub.header {
      display: inline-block;
      vertical-align: baseline;
      font-size: 13px;
    }
  }
}

.need_to_login {
  height: 60VH;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.recommend-label div {
  color: white;
  background-color: #005697;
  max-width: 120px;
  overflow: hidden;

  .text {
    padding-left: 0.4em;
    padding-right: 0.4em;
    padding-bottom: 0.1em;
    white-space: nowrap;
  }
}

.recommend-more {
  float: right;
}

.rtl .recommend-more {
  float: left;
}

.recommend-more-placeholder {
  height: 1.4em;
}

.font_black {
  color: $black !important;
}

.latestUpdatesCardGroup {
  padding-top: 0 !important;
  position: relative;

  .card.cu_item {


    .cu_item_img {
      min-height: 124px;
    }
  }

  .scroll_intents {
    position: absolute;
    top: 0;
    width: 40px;
    height: calc(100% - 22px);
    margin-top: 12px;
    z-index: 10;

    .icon {
      transform: scale(2, 3.5);
      color: #0000008A;
    }
  }

  .ui.basic.button {
    background-color: #fff !important;
  }
}

.latestUpdatesCardGroupMobile {
  margin-top: 0 !important;
}

.language-mobile-select {
  border: 1px solid rgba(34, 36, 38, .15);
  border-radius: 0.28571429rem;
  padding: .78571429em 1em .78571429em 1em;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.list-first-item {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
