$bg-black: rgba($fullBlack, .4) !default;
$transparent: rgba($fullBlack, 0) !default;

.image-gallery {
  user-select: none;
  -webkit-tap-highlight-color: $transparent;

  &:-webkit-full-screen {
    height: 100vh;
    overflow: hidden;
    width: 100vw;
  }
}

.image-gallery-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 0;
  top: 0;

  &.fullscreen {
    background: $black;
    height: 100%;

    .image-gallery-slide {
      background: $black;
    }
  }
}

.image-gallery-slide-wrapper {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
  position: relative;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button,
.image-gallery-left-nav,
.image-gallery-right-nav {
  background: rgba($black, .4) !important;
  margin: 0 !important;
  position: absolute;
  z-index: 4;
  &:hover { background: rgba(lighten($black, 5), .4) !important;}
  &:focus { background: rgba(lighten($black, 8), .4) !important;}
  &:active { background: rgba(lighten($black, 10), .4) !important; }
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  top: .5rem;
}

.image-gallery-fullscreen-button {
  right: .5rem;
}

.image-gallery-play-button {
  left: 0;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  top: 50%;
  transform: translateY(-50%);

  &[disabled] {
    cursor: disabled;
    opacity: .6;
    pointer-events: none;
  }

  // @media (max-width: $tabletBreakpoint) {
  // }

  @media (max-width: $mobileBreakpoint) {
    display: none !important;
  }
}

.image-gallery-left-nav {
  border-radius: 0 .28571429rem .28571429rem 0 !important;
  left: 0;
}

.image-gallery-right-nav {
  border-radius: .28571429rem 0 0 .28571429rem !important;
  right: 0;
}

.image-gallery-swipe {
  display: flex;
  flex: 1 1 auto;
  overflow: hidden;
}

.image-gallery-slides {
  flex: 1 1 auto;
  line-height: 0;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.image-gallery-slide {
  background: $white;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &.image-gallery-center {
    position: relative;
  }

  .image-gallery-image {
    display: flex;
    flex: 1 1 auto;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  .image-gallery-description {
    background: $bg-black;
    bottom: 70px;
    color: $white;
    left: 0;
    line-height: 1;
    padding: 10px 20px;
    position: absolute;
    white-space: normal;

    @media (max-width: $tabletBreakpoint) {
      bottom: 45px;
      font-size: .8em;
      padding: 8px 15px;
    }

  }
}

.image-gallery-bullets {
  bottom: 20px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  width: 80%;
  z-index: 4;

  .image-gallery-bullets-container {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .image-gallery-bullet {
    appearance: none;
    background-color: transparent;
    border: 1px solid $white;
    border-radius: 50%;
    box-shadow: 0 1px 0 lighten($black, 10%);
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    outline: none;
    padding: 5px;

    @media (max-width: $tabletBreakpoint) {
      margin: 0 3px;
      padding: 3px;
    }

    @media (max-width: $tabletBreakpoint) {
      padding: 2.7px;
    }

    &.active {
      background: $white;
    }
  }
}

.image-gallery-thumbnails-wrapper {
  flex: 0 0 auto;
  position: relative;
}

.image-gallery-thumbnails {
  overflow: hidden;
  padding: 5px 0;

  @media (max-width: $tabletBreakpoint) {
    padding: 3px 0;
  }

  .image-gallery-thumbnails-container {
    cursor: pointer;
    text-align: center;
    transition: transform .45s ease-out;
    white-space: nowrap;
  }

}

.image-gallery-thumbnail {
  border: 2px solid transparent;
  display: inline-block;
  transition: border .3s ease-out;
  width: 100px;

  @media (max-width: $tabletBreakpoint) {
    width: 75px;
  }

  + .image-gallery-thumbnail {
    margin-left: 2px;
  }

  img {
    vertical-align: middle;
    width: 100%;
  }

  &.active {
    border: 2px solid $blue;
    // @media (max-width: $tabletBreakpoint) {
    // }
  }
}

.image-gallery-thumbnail-label {
  color: lighten($black, 10%);
  font-size: 1em;

  @media(max-width: $tabletBreakpoint) {
    font-size: .8em;
  }
}

.image-gallery-index {
  background: $bg-black;
  bottom: .5rem;
  color: $white;
  line-height: 1;
  padding: 10px 20px;
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  z-index: 4;

  @media(max-width: $tabletBreakpoint) {
    font-size: .8em;
    padding: 5px 10px;
  }
}
