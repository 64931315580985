.topics {
  &__section {
    width: 33.33333333%;

    @include mobile {
      width: 100% !important;
      margin-bottom: 4rem;
    }
    @include small-mobile {
      width: 100% !important;
      margin-bottom: 4rem;
    }
  }

  @mixin title-font {
    margin: 0 -2px 10px -3px !important;
    padding: 20px 2px 10px 3px !important;
    line-height: 30px !important;
    font-weight: bold !important;
    color: #1a4179 !important;
    margin-bottom: 1.5rem !important;
    border-bottom: 1px solid #f2f2f2 !important;
    position: sticky;
    top: 0px;
    z-index: 10;
  }

  &__title-font {
    @include title-font;
  }

  &__title {
    @include title-font;
    background-color: #fff;
  }

  @mixin item-font {
    line-height: 24px !important;
    font-size: 16px !important;
    padding: 0 !important;
    margin-bottom: 4px !important;
  }

  &__item-font {
    @include item-font;
  }

  &__item-smaller-font {
    font-size: 14px !important;
    color: rgb(160, 155, 155);
  }

  &__list {
    .topics__card {
      display: block;
      margin-bottom: 32px;
      padding: 4px 8px;
      background-color: #f3f4f5;
      min-height: 176px;

      .header.topics__subtitle {
        padding: 10px 8px !important;
        font-size: 18px !important;
        line-height: 22px !important;
        font-weight: 700;
        position: sticky;
        top: 58px;
        z-index: 9;
        background-color: #f3f4f5;
        box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.24);
        margin: -4px -7.9px 8px -7.9px;
        color: #00aced !important;

        // a changes its color if not specified
        a {
          color: #00aced !important;
        }
      }

      .item {
        @include item-font;

        &.subTopic {
          margin-bottom: 0 !important;
        }

        &.hide-topic {
          display: none !important;
        }

        .list {
          padding-top: 0.25em;
          padding-bottom: 0.1em;
        }
      }

      .topics__button {
        box-shadow: none !important;
        padding: 7px 0 !important;
        color: #828282 !important;
        font-size: 16px;

        &.hide-button {
          visibility: hidden;
        }

        &:hover, &:focus {
          background-color: transparent !important;
          color: #828282 !important;
        }

        &:active {
          font-size: 14px;
        }

        .icon {
          color: #00aced !important;
        }
      }

      &.no_height {
        min-height: unset;
      }

      &.grandchildren {
        min-height: unset;
        margin-bottom: 0;

        .header {
          box-shadow: none !important;
          margin-bottom: 0 !important;
          padding-bottom: 0 !important;
          color: #1a4179 !important;
          position: static !important;
        }

        .hide-button {
          display: none;
        }
      }
    }
  }

  .ui.breadcrumb {
    font-weight: bold;

    .icon.divider {
      font-size: 1.1em;
      vertical-align: middle;
    }
  }
}



