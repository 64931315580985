
.text__content-markers {
  min-width: 60px;
  height: 0;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: end;

  .rtl & {
    padding-left: 5px;
  }

  .ltr & {
    padding-right: 5px;
  }

  .note_marks {
    width: 40px;
    height: 100%;
    z-index: 3;

    .note_mark {
      position: absolute;
      width: 25px;
      left: 5px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;

      & > .button {
        z-index: 1;

        .label {
          margin: 0 !important;
          top: -6px;
          left: auto;
          right: -12px;
          padding: 4px !important;
          min-height: 22px;
          min-width: 22px;
          border: 1px solid $light-stroke;
          font-size: 13px;

          .is-sepia & {
            border-color: $sepia-stroke;
          }

          .is-dark & {
            border-color: $dark-stroke;
          }
        }

        .material-symbols-outlined {
          color: $light-text;

          .is-sepia & {
            color: $sepia-text;
          }

          .is-dark & {
            color: $dark-text
          }
        }
      }
    }
  }
}

.ui.popup.label_mark_popup {
  opacity: 0.95;

  .link_to_cu {
    padding: 2px 3px !important;
    font-size: smaller;
    background: $midGrey !important;
    color: white !important;

    &:hover {
      background-color: $sepia-bg;
    }
  }
}

.note_list {
  width: 370px;
  //box-shadow: 0 4px 20px 0 lighten($black, 10%);
  position: absolute;
  top: 0;
  left: auto;
  right: -380px;
  z-index: 1;

  .rtl & {
    right: auto;
    left: -370px;
  }

  &.note_list_out {
    right: auto;
    left: -380px;

    .rtl & {
      left: auto;
      right: -380px;
    }
  }
}


.note_item {
  background: #F2F7FF;
  width: 100%;
  padding: 8px;
  margin: 8px 0;


  .note_info {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
    color: #767676;
    margin: 0 8px;

    .note_buttons {
      display: flex;
      flex-wrap: nowrap;
      opacity: 0;

      .button {
        margin-right: 10px;
        background: none;

        &:first-child {
          margin-right: 0;
        }

        .material-symbols-outlined {
          color: rgba(0, 0, 0, 0.54) !important;
        }
      }
    }
  }

  &:hover .note_buttons {
    opacity: 1;
  }

  .note_content {
    margin: 8px;
    font-size: 16px;
    line-height: 20px;
    color: $black;
    white-space: pre-line;

    &_short {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    textarea {
      width: 100%;
      min-height: 250px;
      padding: .5em;
      outline: none;
    }
  }

  .note_edit_btns {
    margin-top: 1em;
    display: flex;
    justify-content: flex-end;

    > .button:last-child {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &.note_item_sticky {
    position: fixed;
    bottom: 0;
    width: 400px;
    z-index: 4;

    .note_buttons {
      opacity: 1;
    }

    .note_content {
      max-height: 50VH;
      min-height: 250px;
      overflow-x: auto;
    }
  }

  &.note_modal {
    padding: 0;

    .note_buttons {
      opacity: 1;
    }

    .note_content {
      max-height: 50VH;
      min-height: 250px;
      overflow-x: auto;
    }
  }
}
